.heatmap-container {
  position: relative;
  font-family: 'Roboto', sans-serif;
  background-color: var(--body-background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.heatmap-table-wrapper {
  overflow-x: auto;
}

.heatmap-table-wrapper::-webkit-scrollbar {
  height: 12px;
}

.heatmap-table-wrapper::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

.heatmap-table-wrapper::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 6px;
  filter: brightness(0.8);
}

.heatmap-table-wrapper::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}

.heatmap-table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.heatmap-table th,
.heatmap-table td {
  border: 1px solid var(--grey);
  text-align: center;
  width: 100px;
  height: 40px;
  padding: 0.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.heatmap-table th {
  background-color: var(--darker);
  color: var(--heading-color);
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  position: relative;
}

.heatmap-table th:hover {
  overflow: visible;
  position: relative;
  z-index: 20;
  color: var(--weird);
  background: var(--darkest);
}

.heatmap-table th:hover span {
  overflow: visible;
  position: relative;
  z-index: 20;
  height: 40px;
  color: var(--weird);
  background: var(--darkest);
}

.sticky-node {
  position: sticky;
  left: 0;
  background-color: var(--dark);
  color: var(--heading-color);
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.sticky-node:hover {
  overflow: visible;
  z-index: 20;
  color: var(--weird);
  background: var(--darkest);
}

.sticky-node:hover span {
  position: sticky;
  left: 0;
  z-index: 20;
  height: 40px;
  color: var(--weird);
  background: var(--darkest);
}

.heatmap-table td:hover {
  transform: scale(1.05);
  color: var(--weird);
  z-index: 2;
}

.heatmap-tooltip {
  position: fixed;
  background-color: var(--darker);
  color: var(--lighter, #ffffff);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--dark);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  z-index: 10;
  width: 260px;
  transform: translateY(10px) scale(0.95);
  opacity: 0;
  overflow: hidden;
  animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(15px) scale(0.9); /* Start slightly below and scaled */
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1); /* End at normal position */
  }
}

.heatmap-tooltip-header {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--weird, #eeeeee);
  text-align: center;
}

.heatmap-tooltip-subheader {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--lightest);
  text-align: center;
}

.heatmap-tooltip-subsubheader {
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--light);
  text-align: center;
}

.heatmap-tooltip-body {
  display: flex;
  flex-direction: column;
}

.heatmap-tooltip-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
}

.heatmap-tooltip-row-label {
  font-weight: bold;
  color: var(--lightest);
}

.heatmap-tooltip-row-value {
  color: var(--lighter);
  text-align: right;
}

.heatmap-tooltip-divider {
  height: 1px;
  background-color: var(--grey);
  margin: 8px 0;
  opacity: 0.5;
}

.heatmap-tooltip-mini-divider {
  height: 1px;
  background-color: var(--grey);
  margin: 0;
  opacity: 0.5;
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filters select {
  padding: 5px;
  border: 1px solid var(--grey);
  border-radius: 5px;
  font-size: 14px;
  background-color: var(--button-background);
  color: var(--button-text-color);
  outline: none;
}

.filters select:hover {
  background-color: var(--button-hover-background);
}

.filters option {
  background-color: var(--darker);
  color: var(--text-color);
}
